import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import ProductTable, {TableTypes} from './ProductTable';
import {Checkbox, Input, Select} from 'antd';
import PricingTypes from '../../pricingTypes';
import {navigate} from 'gatsby';
import {errorHandler} from '../../errors';
import {useOutlet} from 'reconnect.js';
import Block from '../../Widgets/Block';
import Button from '../../Widgets/Button';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';

let pricingTypeOptions = [...PricingTypes.pricingTypes].map((x) => ({
  label: x,
  value: x,
}));
pricingTypeOptions.unshift({label: '全部', value: 'all'});

const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

const ORDERING = {
  '-updated': '最新編輯',
  id: '編號',
  // "name": "名稱"
};

export default function ProductList(props) {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actions] = useOutlet('actions');
  const [filters, setFilters] = useState({
    ...PAGINATION_INIT,
    calc_type: 'all',
    is_on_shelf: true,
    ordering: '-updated',
    search: '',
  });
  const [total, setTotal] = useState(0);

  const filtersChange = useCallback((values) => {
    setFilters((prev) => ({...prev, ...values}));
  }, []);

  const getRecords = useCallback(async () => {
    let {current, pageSize, is_on_shelf, search, ordering} = filters;
    actions.setLoading(true);

    try {
      let resp = await actions.getProducts({
        offset: (current - 1) * pageSize,
        limit: pageSize,
        ordering,
        search,
        ...(is_on_shelf && {is_on_shelf: true}),
      });
      setRecords(resp.results);
      setTotal(resp.count);
    } catch (ex) {
      errorHandler(ex, '取得商品列表錯誤');
    }
    actions.setLoading(false);
  }, [actions, filters]);

  const onDelete = async (instance) => {
    setLoading(true);
    try {
      await actions.deleteProduct(instance.id);
      await getRecords();
    } catch (err) {
      errorHandler(err, '刪除商品錯誤');
    }
    setLoading(false);
  };

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  return (
    <Wrapper>
      <Block style={{marginBottom: 20}}>
        <div>
          <div className="row">
            <h4>排序</h4>
            <Select
              value={filters.ordering}
              onChange={(value) =>
                filtersChange({
                  ordering: value,
                  ...PAGINATION_INIT,
                })
              }
              style={{marginRight: 40}}>
              {Object.keys(ORDERING).map((x) => (
                <Select.Option value={x} key={x}>
                  {ORDERING[x]}
                </Select.Option>
              ))}
            </Select>
            <h4>搜尋名稱</h4>
            <Input.Search
              allowClear
              onSearch={(value) =>
                filtersChange({
                  search: value,
                  ...PAGINATION_INIT,
                })
              }
            />
          </div>
          <div className="row" style={{margin: 0}}>
            <h4>上架</h4>
            <Checkbox
              checked={filters.is_on_shelf}
              onChange={(e) =>
                filtersChange({
                  is_on_shelf: e.target.checked,
                  ...PAGINATION_INIT,
                })
              }
            />
          </div>
        </div>

        <div className="divider" />
        <div className="row" style={{margin: 0}}>
          <div style={{flex: 1}} />
          <Button
            style={{marginRight: 10}}
            onClick={() => navigate(`/product/`)}>
            新增商品
          </Button>
          <Button
            type="primary"
            onClick={getRecords}
            loading={loading}
            icon={<Refresh color="#fff" size={20} style={{marginRight: 5}} />}>
            刷新
          </Button>
        </div>
      </Block>

      <ProductTable
        type={TableTypes.NORMAL}
        loading={loading}
        records={records}
        mounted={true}
        onDelete={onDelete}
        pagination={{
          pageSize: filters.pageSize,
          current: filters.current,
          total,
        }}
        onPaging={(pagination) =>
          filtersChange({
            pageSize: pagination.pageSize,
            current: pagination.current,
          })
        }
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;

import React from 'react';
import {Table, Button} from 'antd';
import Tag from '../../Widgets/Tag';
import moment from 'moment';
import Anchor from '../../Widgets/Anchor';

const TableTypes = {
  NORMAL: 0,
  MANUAL: 1,
  IN_ORDER: 2,
};

const ProductTable = ({
  records,
  loading,
  onItemClick,
  onDelete,
  mounted = false,
  type = TableTypes.NORMAL,
  onPaging = () => {},
  pagination,
}) => {
  const columns = [
    {
      title: '編號',
      render: (_, instance) => (
        <Anchor to={`/product/?id=${instance.id}`}>{instance.id}</Anchor>
      ),
      width: 50,
      fixed: 'left',
    },
    {
      title: '商品名稱',
      dataIndex: 'name',
      width: 200,
    },
    {
      title: '規格種類',
      dataIndex: 'calc_type',
      width: 80,
    },
    {
      title: '上架',
      render: (_, instance) =>
        instance.is_on_shelf ? (
          <Tag color="#4BAA70">是</Tag>
        ) : (
          <Tag color="#999">否</Tag>
        ),
      width: 60,
    },
    {
      title: '建立日期',
      render: (_, instance) =>
        moment(instance.created).format('YYYY-MM-DD HH:mm'),
      width: 150,
    },
    {
      title: '刪除',
      render: (_, instance) => (
        <Button
          type="primary"
          danger
          onClick={() => {
            if (window.confirm('確定要刪除？')) {
              onDelete(instance);
            }
          }}>
          刪除
        </Button>
      ),
      width: 80,
    },
  ];

  const extraProps = mounted
    ? {
        scroll: {
          x: window.innerWidth - 200 - 40,
        },
      }
    : {};

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={records}
      rowKey={(item) => item.id}
      pagination={type !== TableTypes.IN_ORDER ? pagination : false}
      onChange={onPaging}
      {...extraProps}
    />
  );
};

export default ProductTable;

export {TableTypes};
